function showMessage(){
    $('#fa-icon-message').hide();
            $('#fa-icon-close').show();
            $('#float-message').show(500);
}

function hideMessage(){
    $('#fa-icon-message').show();
            $('#fa-icon-close').hide();
            $('#float-message').hide(500);
}