var etk;
var pke;
var width = 256;
var height = 394;
var halfSize = 0;
var consolidate = 0;
var umbral = 80;
var timeout = 30;
var token = "";
var imgFlag = 1;
var visible = 0;
var response = "Data adicional";
var ie8 = false;
var host = "127.0.0.1";

function demoAsync(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocUsuario, tiDocCliente) {
  //return await autenticacionBiometrica(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocUsuario, tiDocCliente);
  var responseBiometrico;
  var puerto = 2225;
  var params = "r=" + Math.random();
  var url = "https://" + host + ":" + puerto + "/biomatchwsi";
  //var xhr = createCORSRequest('POST', url);
  //xhr.setRequestHeader("Content-Type", "text/plain");
  //xhr.onload = function (e) {
  //  var response = xhr.responseText;
  //  console.log(response);
  //};
  //await xhr.send(buildXmlVcRequest(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocCliente, tiDocUsuario));

  var result = testAjax(url, dataTest(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocCliente, tiDocUsuario));
  return result;
}

var testAjax = function (url, data) {
  var result = '';
  $.ajax({
    url: url,
    async: false,
    type: "POST",
    dataType: "xml",
    data: data,
    complete: function (data) {
      //result = data.responseText;
      result = vcSuccess(data.responseText, data);
    },
    contentType: "text/xml; charset=\"utf-8\""
  });

  return result;
};


async function autenticacionBiometrica(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocUsuario, tiDocCliente) {
  try {
    var responseBiometrico;
    var puerto = 2225;
    var params = "r=" + Math.random();
    var url = "https://" + host + ":" + puerto + "/biomatchwsi";
    var xhr = await createCORSRequest('POST', url);

    

    //var coProceso = "1234";
    //var ipConsulta = "192.168.54.128";
    //var nuDocUsuario = "40689775";
    //var nuDocCliente = "48066128";
    //var tiDocUsuario = "1";
    //var tiDocCliente = "1";

    if (ie8) {
      var params = "r=" + Math.random() + "&op=autenticacionBiometrica" +
        "&coProceso=" + coProceso + "&ipConsulta=" + ipConsulta + "&nuDocCliente=" + nuDocCliente +
        "&nuDocUsuario=" + nuDocUsuario + "&tiDocCliente=" + tiDocCliente + "&tiDocUsuario=" + tiDocUsuario;
      xhr = await createCORSRequest('GET', url + "?" + params);
      
    }
    if (!xhr) {
      console.log('Su browser no soporta Componentes Ajax, Por favor utilizar IE 8 or higher,FireFox 4.0 or higher');
      return;
    }

    var handle_load = await function (event_type) {
      return function (XHRobj) {
        var XHRobj = is_iexplorer() ? xhr : XHRobj;
        if (event_type == 'load' && (is_iexplorer() || XHRobj.readyState == 4) && vcSuccess) {
          //vcSuccess(XHRobj.responseText, XHRobj);
          console.log('aea', XHRobj.responseText);
          console.log('bcd', XHRobj);
        }
        else if (vcError)
          vcError(XHRobj);
      }
    };

    xhr.onload = function (e) {
      handle_load('load')(is_iexplorer() ? e : e.target)
    };

    xhr.onerror = function (e) {
      handle_load('error')(is_iexplorer() ? e : e.target)
    };
    console.log(' Procesando... ');

    

    if (ie8) {
      xhr.send();
    } else {
      //alert("NO IE8");
      xhr.setRequestHeader("Content-Type", "text/plain"); // nuevo agregado
      //responseBiometrico = xhr;
      try {
        //xhr.setRequestHeader("Content-Type", "text/plain"); // nuevo agregado
        //xhr.setRequestHeader("Access-Control-Allow-Origin","*")
        await xhr.send(buildXmlVcRequest(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocCliente, tiDocUsuario));
        responseBiometrico = xhr;
      } catch (err) {
          //alert("err.description:"+err.description);
        console.log("error ", err.description);
      }
    }
   
    console.log("Ok Data----------->");

  } catch (err) {
    console.log("BioMatchClient Service not running!", err.description);
  }
  
  return responseBiometrico;
}




function createCORSRequest(method, url) {
  var xhr = new XMLHttpRequest();
  if ("withCredentials" in xhr) {
    //alert("createCORSRequest_1");
    // XHR for Chrome/Firefox/Opera/Safari.
    ie8 = false;
    xhr.open(method, url, true);
    xhr.setRequestHeader("Content-Type", "text/xml");
    //xhr.setRequestHeader("Content-Type", "text/plain");
  } else if (typeof XDomainRequest != "undefined") {
    //alert("createCORSRequest_2");
    ie8 = true;
    xhr = new XDomainRequest();
    xhr.open(method, url);
  } else {
    //alert("createCORSRequest_3");
    xhr = null;
  }
  //console.log("xhr Value ---> ", xhr);
  return xhr;
}

function dataTest(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocCliente, tiDocUsuario) {
  var xmlToSend = "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:wsi='http://wsi.client.match.bio.zy.com/'>";
  xmlToSend += "  <soapenv:Header/>";
  xmlToSend += "  <soapenv:Body>";
  xmlToSend += "   <wsi:autenticacionBiometrica>";
  xmlToSend += "     <inputBean>";
  xmlToSend += "        <coProceso>" + coProceso + "</coProceso>";
  xmlToSend += "        <ipConsulta>" + ipConsulta + "</ipConsulta>";
  xmlToSend += "        <nuDocCliente>" + nuDocCliente + "</nuDocCliente>";
  xmlToSend += "        <nuDocUsuario>" + nuDocUsuario + "</nuDocUsuario>";
  xmlToSend += "        <tiDocCliente>" + tiDocCliente + "</tiDocCliente>";
  xmlToSend += "        <tiDocUsuario>" + tiDocUsuario + "</tiDocUsuario>";
  xmlToSend += "      </inputBean>";
  xmlToSend += "   </wsi:autenticacionBiometrica>";
  xmlToSend += "  </soapenv:Body>";
  xmlToSend += "</soapenv:Envelope>";

  return xmlToSend;
}

function buildXmlVcRequest(coProceso, ipConsulta, nuDocUsuario, nuDocCliente, tiDocCliente, tiDocUsuario) {

  var xmlToSend = "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:wsi='http://wsi.client.match.bio.zy.com/'>";
  xmlToSend += "  <soapenv:Header/>";
  xmlToSend += "  <soapenv:Body>";
  xmlToSend += "   <wsi:autenticacionBiometrica>";
  xmlToSend += "     <inputBean>";
  xmlToSend += "        <coProceso>" + coProceso + "</coProceso>";
  xmlToSend += "        <ipConsulta>" + ipConsulta + "</ipConsulta>";
  xmlToSend += "        <nuDocCliente>" + nuDocCliente + "</nuDocCliente>";
  xmlToSend += "        <nuDocUsuario>" + nuDocUsuario + "</nuDocUsuario>";
  xmlToSend += "        <tiDocCliente>" + tiDocCliente + "</tiDocCliente>";
  xmlToSend += "        <tiDocUsuario>" + tiDocUsuario + "</tiDocUsuario>";
  xmlToSend += "      </inputBean>";
  xmlToSend += "   </wsi:autenticacionBiometrica>";
  xmlToSend += "  </soapenv:Body>";
  xmlToSend += "</soapenv:Envelope>";

  //alert(xmlToSend);
  if (window.DOMParser) {
    //        //alert("1");
    var parser = new DOMParser();
    //        //alert("1.1");
    var xmldoc = parser.parseFromString(xmlToSend, "text/xml");
    //        //alert("1.2:"+xmldoc);
  } else {
    //        //alert("2");
    var xmldoc = new ActiveXObject("Microsoft.XMLDOM");
    xmldoc.async = "false";
    xmldoc.loadXML(xmlToSend);
  }
  return xmldoc;
}

function is_iexplorer() {
  return navigator.userAgent.indexOf('MSIE') != -1;
}

function parseXMLDoc(xmlDocText) {
  if (window.DOMParser) {
    var parserXml = new DOMParser();
    var xmlDocument = parserXml.parseFromString(xmlDocText, "text/xml");
  } else {// Internet Explorer    
    var xmlDocument = new ActiveXObject("Microsoft.XMLDOM");
    xmlDocument.async = false;
    xmlDocument.loadXML(xmlDocText);
  }
  return xmlDocument;
}

function vcSuccess(responseText, XHRobj) {
  //alert("vcSuccess");
  var coMensaje;
  var deMensaje;
  var xmlDocument;
  var coResultado;
  var deResultado;
  var nomCliente;
  var apPatCliente;
  var apMatCliente;
  var feTxn;
  var indQA;
  var nuTxn;
  var tiDocCliente;
  var vigencia;
  var nuDocCliente;

  var resultado;

  if (!ie8) {

    xmlDocument = parseXMLDoc(responseText);

    coMensaje = xmlDocument.getElementsByTagName("coMensaje")[0].childNodes[0].nodeValue;
    deMensaje = xmlDocument.getElementsByTagName("deMensaje")[0].childNodes[0].nodeValue;
    console.log("coMensaje : ", coMensaje);
    console.log("deMensaje : ", deMensaje);

    //cleanLabels();
    if (coMensaje === "8000") {
      //document.getElementById('msgLabel').style.color = "#00F";

      //console.log("nuDocCliente : ", nuDocCliente = xmlDocument.getElementsByTagName("nuDocCliente")[0].childNodes[0].nodeValue);
      //console.log("coResultado : ",coResultado = xmlDocument.getElementsByTagName("coResultado")[0].childNodes[0].nodeValue);
      //console.log("deResultado : ", deResultado = xmlDocument.getElementsByTagName("deResultado")[0].childNodes[0].nodeValue);
      //console.log("nomCliente : ", nomCliente = xmlDocument.getElementsByTagName("nomCliente")[0].childNodes[0].nodeValue);
      //console.log("apPatCliente : ", apPatCliente = xmlDocument.getElementsByTagName("apPatCliente")[0].childNodes[0].nodeValue);
      //console.log("apMatCliente : ", apMatCliente = xmlDocument.getElementsByTagName("apMatCliente")[0].childNodes[0].nodeValue);
      //console.log("feTxn : ", feTxn = xmlDocument.getElementsByTagName("feTxn")[0].childNodes[0].nodeValue);
      //console.log("indQA : ", indQA = xmlDocument.getElementsByTagName("indQA")[0].childNodes[0].nodeValue);
      //console.log("nuTxn : ", nuTxn = xmlDocument.getElementsByTagName("nuTxn")[0].childNodes[0].nodeValue);
      //console.log("tiDocCliente : ", tiDocCliente = xmlDocument.getElementsByTagName("tiDocCliente")[0].childNodes[0].nodeValue);
      //console.log("vigencia : ", vigencia = xmlDocument.getElementsByTagName("vigencia")[0].childNodes[0].nodeValue);

      resultado = {
        "coMensaje": xmlDocument.getElementsByTagName("coMensaje")[0].childNodes[0].nodeValue,
        "deMensaje": xmlDocument.getElementsByTagName("deMensaje")[0].childNodes[0].nodeValue,
        "numDoc": xmlDocument.getElementsByTagName("nuDocCliente")[0].childNodes[0].nodeValue,
        "codResul": xmlDocument.getElementsByTagName("coResultado")[0].childNodes[0].nodeValue,
        "deResult": xmlDocument.getElementsByTagName("deResultado")[0].childNodes[0].nodeValue,
        "indQA": xmlDocument.getElementsByTagName("indQA")[0].childNodes[0].nodeValue,
        "numTxn": xmlDocument.getElementsByTagName("nuTxn")[0].childNodes[0].nodeValue
      };

    } else {
      //console.log("Code ERROR ELSE ");
      resultado = {
        "coMensaje": xmlDocument.getElementsByTagName("coMensaje")[0].childNodes[0].nodeValue,
        "deMensaje": xmlDocument.getElementsByTagName("deMensaje")[0].childNodes[0].nodeValue
      };
    }

  } else {    
    //alert(responseText);
    var arr = responseText.split("#");
    ////alert("datos array:" + arr.length);
    coMensaje = arr[0];
    deMensaje = arr[1];

    //console.log("coMensaje : ", coMensaje);
    //console.log("deMensaje : ", deMensaje);

    resultado = {
      "coMensaje": coMensaje,
      "deMensaje": deMensaje
    };
    ////alert(coMensaje);
    ////alert(deMensaje);
    //document.getElementById('msgLabel').innerHTML = deMensaje;
    //cleanLabels();

    if (coMensaje === "8000") {
      //document.getElementById('msgLabel').style.color = "#00F";

      //coResultado = arr[2];
      //document.getElementById('coResultado').style.color = "#00F";
      //document.getElementById('coResultado').innerHTML = coResultado;

      //deResultado = arr[3];
      //document.getElementById('deResultado').style.color = "#00F";
      //document.getElementById('deResultado').innerHTML = deResultado;

      //tiDocCliente = arr[4];
      //document.getElementById('tiDocCliente').style.color = "#00F";
      //document.getElementById('tiDocCliente').innerHTML = tiDocCliente;

      //nuDocCliente = arr[5];
      ////alert("nuDocCliente:" + nuDocCliente);
      //document.getElementById('nuDocClienteResp').style.color = "#00F";
      //document.getElementById('nuDocClienteResp').innerHTML = nuDocCliente;

      //apPatCliente = arr[6];
      //document.getElementById('apPatCliente').style.color = "#00F";
      //document.getElementById('apPatCliente').innerHTML = apPatCliente;

      //apMatCliente = arr[7];
      //document.getElementById('apMatCliente').style.color = "#00F";
      //document.getElementById('apMatCliente').innerHTML = apMatCliente;

      //nomCliente = arr[8];
      //document.getElementById('nomCliente').style.color = "#00F";
      //document.getElementById('nomCliente').innerHTML = nomCliente;

      //vigencia = arr[10];
      //document.getElementById('vigencia').style.color = "#00F";
      //document.getElementById('vigencia').innerHTML = vigencia;


      //nuTxn = arr[12];
      //document.getElementById('nuTxn').style.color = "#00F";
      //document.getElementById('nuTxn').innerHTML = nuTxn;

      //feTxn = arr[13];
      //document.getElementById('feTxn').style.color = "#00F";
      //document.getElementById('feTxn').innerHTML = feTxn;

      //indQA = arr[14];
      //document.getElementById('indQA').style.color = "#00F";
      //document.getElementById('indQA').innerHTML = indQA;

      console.log("OK RESPONSE 2");

    } else {
      //document.getElementById('msgLabel').style.color = "#F00";
      console.log("ERROR RESPONSE 2");
    }
  }

  return resultado;
}



function vcError(XHRobj) {
  //document.getElementById('msgLabel').style.color = "#F00";
  //document.getElementById('msgLabel').innerHTML = 'Ups, se produjo un inconveniente accesando al BioMatchClient (Huellero), por favor verifique!';
}

function hex2a(hexx) {
  var hex = hexx.toString();//force conversion
  var str = '';
  for (var i = 0; (i < hex.length && hex.substr(i, 2) !== '00'); i += 2)
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str;
}

function hexToBase64(str) {
  return btoa(String.fromCharCode.apply(null,
    str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
  );
}
